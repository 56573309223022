<template>
  <div>
    <v-simple-table
      fixed-header
      height="calc(100vh - 265px)"
      class="table-padding-2-no-top pr-md-5"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader" class="text-center" style="width: 120px">
              <SelectWarehouse
                :label="$t('labels.warehouse')"
                :placeholder="$t('labels.warehouse')"
                name="id_warehouse"
                sort-name="warehouse_code"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>

            <th role="columnheader" class="text-center">
              <InputFilter
                :label="$t('labels.config')"
                :placeholder="$t('labels.config')"
                name="name"
                sort-name="name"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>

            <th>
              <SelectFilter
                :options="yesNoOptions"
                :label="$t('labels.status')"
                :placeholder="$t('labels.status')"
                name="status"
                sort-name="status"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>

            <th role="columnheader" class="text-center">
              <v-btn
                color="success"
                small
                @click="addItem"
                :disabled="isDisabledBtnAddItem"
              >
                {{ $t("labels.add") }}
              </v-btn>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in items"
            :key="`i_${index}_${item.id}`"
            class="text-center"
          >
            <td>
              <v-autocomplete
                :disabled="!item.editing"
                class="c-input-small"
                v-model="item.id_warehouse"
                :items="warehouses"
                dense
                outlined
                hide-details
                single-line
              ></v-autocomplete>
            </td>
            <td>
              <v-autocomplete
                :disabled="!item.editing"
                class="c-input-small"
                v-model="item.id_warning_config"
                :items="configs"
                dense
                outlined
                hide-details
                single-line
              ></v-autocomplete>
            </td>
            <td class="text-center vertical-align-middle">
              <div class="d-flex align-center justify-center">
                <v-checkbox
                  v-model="item.status"
                  :disabled="!item.editing"
                  class="mt-0 ml-1"
                  :value="1"
                  hide-details
                ></v-checkbox>
              </div>
            </td>
            <td>
              <v-btn
                v-if="!item.editing"
                x-small
                color="warning"
                @click="toggleEditing(item, index, true)"
              >
                {{ $t("labels.edit") }}
              </v-btn>
              <template v-else>
                <v-btn
                  class="mb-1"
                  x-small
                  color="success"
                  @click="updateItem(item)"
                >
                  {{ $t("labels.save") }}
                </v-btn>
                <br />
                <v-btn
                  x-small
                  color="error"
                  @click="toggleEditing(item, index, false)"
                  >{{ $t("labels.cancel") }}</v-btn
                >
              </template>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { debounce } from "lodash/function";
import { httpClient } from "@/libs/http";
import { YES_NO_OPTIONS } from "@/libs/const";

export default {
  components: {
    SelectWarehouse: () => import("@/components/table/SelectWarehouse"),
    InputFilter: () => import("@/components/table/InputFilter"),
    SelectFilter: () => import("@/components/table/SelectFilter"),
  },
  name: "WarningConfigWarehouse",
  data: () => ({
    items: [],
    configs: [],
    owners: [],
    filters: {},
    isLoading: false,
    page: 1,
    warehouses: [],
    detail: {},
    yesNoOptions: [...YES_NO_OPTIONS],
  }),
  computed: {
    isDisabledBtnAddItem() {
      const checkItem = [...this.items].find((item) => !item.id);
      return !!checkItem;
    },
  },
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  mounted() {
    this.getWarehouses();
    this.getConfigs();
    this.getList();
  },
  methods: {
    addItem() {
      if (this.isDisabledBtnAdd) {
        return false;
      }
      this.items.unshift({
        id: null,
        id_warehouse: null,
        status: true,
        editing: true,
      });
    },
    toggleEditing(item, index, editing) {
      item.editing = editing;
      if (!item.id) {
        this.items = [...this.items].filter((i, idx) => idx !== index);
      } else {
        this.items[index] = { ...item };
      }
      this.items = [...this.items];
    },
    async updateItem(item) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/warning-wp-save", item);
        this.isLoading = false;
        this.getList();
        const msg = item.id
          ? this.$t("messages.update_success")
          : this.$t("messages.create_success");
        this.$vToastify.success(msg);
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
    getList: debounce(function () {
      httpClient
        .post("/warning-wp-list", {
          ...this.filters,
          page: this.page,
          viewMode: this.viewOption,
        })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.items = [...data.rows];
        });
    }, 500),
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    async getWarehouses() {
      const { warehouses } = window.me;
      this.warehouses = [...warehouses].map((w) => ({
        value: w.id,
        text: w.code_name || "",
      }));
    },
    async getConfigs() {
      const { data } = await httpClient.post("/warning-config-all");
      this.configs = data.rows.map((e) => ({
        value: e.id,
        text: e.name || "",
      }));
    },
  },
};
</script>
